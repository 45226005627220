import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const MinusCircle: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['circle', { cx: '12', cy: '12', r: '10' }],
    ['line', { x1: '8', x2: '16', y1: '12', y2: '12' }],
  ],
];

export default MinusCircle;
