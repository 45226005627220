import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const UserCog2: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M14 19a6 6 0 0 0-12 0' }],
    ['circle', { cx: '8', cy: '9', r: '4' }],
    ['circle', { cx: '19', cy: '11', r: '2' }],
    ['path', { d: 'M19 8v1' }],
    ['path', { d: 'M19 13v1' }],
    ['path', { d: 'm21.6 9.5-.87.5' }],
    ['path', { d: 'm17.27 12-.87.5' }],
    ['path', { d: 'm21.6 12.5-.87-.5' }],
    ['path', { d: 'm17.27 10-.87-.5' }],
  ],
];

export default UserCog2;
