import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const ShoppingBag: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M6 2 3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z' }],
    ['line', { x1: '3', x2: '21', y1: '6', y2: '6' }],
    ['path', { d: 'M16 10a4 4 0 0 1-8 0' }],
  ],
];

export default ShoppingBag;
