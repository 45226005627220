import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Send: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['line', { x1: '22', x2: '11', y1: '2', y2: '13' }],
    ['polygon', { points: '22 2 15 22 11 13 2 9 22 2' }],
  ],
];

export default Send;
