import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const BrainCog: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'M12 4.5a2.5 2.5 0 0 0-4.96-.46 2.5 2.5 0 0 0-1.98 3 2.5 2.5 0 0 0-1.32 4.24 3 3 0 0 0 .34 5.58 2.5 2.5 0 0 0 2.96 3.08A2.5 2.5 0 0 0 9.5 22c1.21 0 2.5-.74 2.5-2.5m0-15a2.5 2.5 0 0 1 4.96-.46 2.5 2.5 0 0 1 1.98 3 2.5 2.5 0 0 1 1.32 4.24 3 3 0 0 1-.34 5.58 2.5 2.5 0 0 1-2.96 3.08A2.5 2.5 0 0 1 14.5 22c-1.21 0-2.5-.74-2.5-2.5m0-15V5m0 14.5V19',
      },
    ],
    ['circle', { cx: '12', cy: '12', r: '2' }],
    ['path', { d: 'M12 9v1' }],
    ['path', { d: 'M12 14v1' }],
    ['path', { d: 'm14.6 10.5-.87.5' }],
    ['path', { d: 'm10.27 13-.87.5' }],
    ['path', { d: 'm14.6 13.5-.87-.5' }],
    ['path', { d: 'm10.27 11-.87-.5' }],
  ],
];

export default BrainCog;
