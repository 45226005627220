import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const DatabaseBackup: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['ellipse', { cx: '12', cy: '5', rx: '9', ry: '3' }],
    ['path', { d: 'M3 5v14c0 1.4 3 2.7 7 3' }],
    ['path', { d: 'M3 12c0 1.2 2 2.5 5 3' }],
    ['path', { d: 'M21 5v4' }],
    [
      'path',
      {
        d: 'M13 20a5 5 0 0 0 9-3 4.5 4.5 0 0 0-4.5-4.5c-1.33 0-2.54.54-3.41 1.41L12 16',
      },
    ],
    ['path', { d: 'M12 12v4h4' }],
  ],
];

export default DatabaseBackup;
