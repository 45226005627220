import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Minus: IconNode = [
  'svg',
  defaultAttributes,
  [['line', { x1: '5', x2: '19', y1: '12', y2: '12' }]],
];

export default Minus;
